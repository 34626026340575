import axios from "axios";

export const tokenGenerator = async () => {
  let response;
  try {
    response = await axios.post(process.env.REACT_APP_API_URL + "token", {
      method: "POST",
    });
  } catch (e) {
    console.log("e", e);
    return null;
  }
  if (response && response.status === 200) {
    localStorage.setItem(
      "token",
      JSON.stringify(response.data.data.access_token)
    );
    return JSON.stringify(response.data.data.access_token);
  }
};

export const getZipCode = async () => {
  let zipCodeData = null;
  let response;
  let token = JSON.parse(localStorage.getItem("token"));
  try {
    response = await axios.get(
      process.env.REACT_APP_DIGI2L_BASE_URL + "/Sponsor/GetZipCodes",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (e) {
    console.log("e", e);
    return null;
  }
  if (response && response.status === 200) {
    return response.data.Detail.ZipCodes;
  }
  return zipCodeData;
};

export const getProductCategory = async () => {
  let response;
  let token = JSON.parse(localStorage.getItem("token"));
  try {
    response = await axios.get(
      process.env.REACT_APP_DIGI2L_BASE_URL + "/Master/GetProductCategory",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (e) {
    console.log("e", e);
    return null;
  }
  if (response && response.status === 200) {
    return response.data.Detail.ProductsCategory;
  }
};

export const getProductType = async (catId) => {
  let response;
  let token = JSON.parse(localStorage.getItem("token"));
  try {
    response = await axios.get(
      process.env.REACT_APP_DIGI2L_BASE_URL +
        `/Master/GetProductType?catId=${catId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (e) {
    console.log("e", e);
    return null;
  }
  if (response && response.status === 200) {
    return response.data.Detail.ProductsType;
  }
};

export const getBrandList = async () => {
  let response;
  let token = JSON.parse(localStorage.getItem("token"));
  try {
    response = await axios.get(
      process.env.REACT_APP_DIGI2L_BASE_URL + `/Master/GetBrand`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (e) {
    console.log("e", e);
    return null;
  }
  if (response && response.status === 200) {
    return response.data.Detail.Brand;
  }
};

export const getProductPrice = async (CategoryID, TypeID, BrandID) => {
  let response;
  let token = JSON.parse(localStorage.getItem("token"));
  try {
    response = await axios.get(
      process.env.REACT_APP_DIGI2L_BASE_URL +
        `/Sponsor/GetProductPrice?catid=${CategoryID}&&typeid=${TypeID}&&brandid=${BrandID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (e) {
    console.log("e", e);
    return null;
  }
  if (response && response.status === 200) {
    return response.data.Detail[0].ProductsFromType[0].Brand.length > 0
      ? response.data.Detail[0].ProductsFromType[0].Brand[0].Price
      : null;
  }
};

export const getOrderId = async (offerId, data) => {
  let valueQueryParams = JSON.parse(localStorage.getItem("item"));
  let oldToken = valueQueryParams.token;
  let response;
  let token = JSON.parse(localStorage.getItem("token"));
  try {
    response = await axios.post(
      process.env.REACT_APP_API_URL +
        `order?rt=save-claim&token=${oldToken}&offer_id=${offerId}`,
      {
        method: "POST",
        body: JSON.stringify(data),
      }
    );
  } catch (e) {
    console.log("e", e);
    return null;
  }
  if (response && response.status === 200) {
    return response.data.order_id;
  }
};

export const createSponserExchangeOrder = async (data) => {
  var myHeaders = new Headers();
  let response;
  let token = JSON.parse(localStorage.getItem("token"));
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(data);
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(
    process.env.REACT_APP_DIGI2L_BASE_URL + "/Sponsor/ProductOrderPlace",
    requestOptions
  )
    .then((d) => {
      response = d;
      return d.text();
    })

    .then((result) => console.log(result))
    .catch((error) => console.log("error", error));
  return response;
};

export const getConfirmOrder = async (offerId, orderId) => {
  let response;
  let item = JSON.parse(localStorage.getItem("item"));
  let token = item.token;
  try {
    response = await axios.get(
      process.env.REACT_APP_API_URL +
        `order?rt=confirm-order&offer_id=${offerId}&order_id=${orderId}&token=${token}`,
      {
        method: "GET",
      }
    );
  } catch (e) {
    console.log("e", e);
    return null;
  }
  if (response && response.status === 200) {
    return response.data;
  }
};
