import React, { useEffect } from "react";
import { tokenGenerator } from "./container/ApiCall/ApiCall";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import Routing from "./Route/Routing";
import { setTokenGen } from "./store/actions/zipCodesDetailSlice";
import { useDispatch } from "react-redux";

const App = () => {
  const dispath = useDispatch();
  useEffect(() => {
    const tokenGen = async () => {
      let token = await tokenGenerator();
      dispath(setTokenGen(token));
    };
    tokenGen();
  }, []);

  return (
    <>
      <BrowserRouter>
        <React.Fragment>
          <Routing />
        </React.Fragment>
      </BrowserRouter>
    </>
  );
};

export default App;
