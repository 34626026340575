import { createSlice } from "@reduxjs/toolkit";

export const zipCodesDetailSlice = createSlice({
  name: "zipCodesDetail",
  initialState: {
    zipCodesData: null,
    selectedZipCode: null,
    tokenGen: null,
  },
  reducers: {
    setZipCodesData: (state, action) => {
      state.zipCodesData = action.payload;
    },
    setSelectedZipCode: (state, action) => {
      state.selectedZipCode = action.payload;
    },
    setTokenGen: (state, action) => {
      state.tokenGen = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setZipCodesData, setSelectedZipCode, setTokenGen } =
  zipCodesDetailSlice.actions;
export const zipCodesData = (state) => {
  return state.zipCodesDetails.zipCodesData;
};
export const selectedZipCode = (state) => {
  return state.zipCodesDetails.selectedZipCode;
};
export const tokenGen = (state) => {
  return state.zipCodesDetails.tokenGen;
};

export default zipCodesDetailSlice.reducer;
