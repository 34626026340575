import { createSlice } from "@reduxjs/toolkit";

export const productDetailSlice = createSlice({
  name: "productDetails",
  initialState: {
    productDetails: null,
  },
  reducers: {
    setProductDetails: (state, action) => {
      state.productDetails = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setProductDetails, setPersonalDetails } =
  productDetailSlice.actions;
export const productStoreData = (state) => {
  return state.productDetails.productDetails;
};
export default productDetailSlice.reducer;
