import { configureStore } from '@reduxjs/toolkit'
import productDetailSlice from './actions/productDetailSlice'
import zipCodesDetailSlice from './actions/zipCodesDetailSlice'
import urlSearchParameterSlice from './actions/urlSearchParameterSlice'
import { reducer as formReducer } from "redux-form";

export default configureStore({
    reducer: {
        productDetails: productDetailSlice,
        zipCodesDetails: zipCodesDetailSlice,
        urlSearchDetails: urlSearchParameterSlice,
        form: formReducer
    },
})